@import 'node_modules/primeflex/primeflex.scss';

@import 'prismjs/themes/prism-coy.css';
@import './AppDemo.scss';

.p-dialog-mask {
    background-color: rgba(97, 97, 97, 0.233) !important;
}

.container-fluid {
    padding: 0px 16px;
}

.logo {
    width: 50px;
}

.nav-user {
    border-left: 3px solid #D9D9D9;
}

.black {
    color: #000 !important;
}

.w-100 {
    width: 100%;
}

.result {
    width: 100%;
    background: #e8e8e87a;
    border-radius: 10px;
    padding: 8px;
    word-break: break-all;
}

.footer-proccess {
    background: white;
    position: absolute;
    bottom: 0;
    padding: 8px;
    width: 100%;
    right: 0px;
    border-top: 1px solid #44486d33;
}

.dashboard {
    background: white;
    padding: 16px;
    border-radius: 10px;
    border: 1px solid #e7e7e7;
}

.dash-header {
    padding: 10px;
    border-bottom: 1px solid #cbcbcb;
}

.archive-ok {
    border-radius: 10px;
    background-color: rgba(167, 214, 171, 0.4);
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.archive-ok .line {
    border-left: 1px solid #F3F5FF;
}

.document {
    border: 2px dashed #d5d5d5;
    border-radius: 20px;
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: space-evenly;
    align-content: space-around;
    color: #d5d5d5;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}

.document-ok {
    border: 2px dashed #d5d5d5;
    border-radius: 20px;
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: space-evenly;
    align-content: space-around;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}

.uma {
    border-radius: 10px;
    padding: 10px;
    margin: 0px 10px;
}

.p-calendar {
    width: 100%;
}

.notificacion {
    width: 20em;
}

.login {
    background: rgba(249, 249, 255, 1);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
    padding: 50px;
    border-radius: 20px;
}

.color-violet {
    color: rgba(110, 65, 147, 1);
}

.p-field-checkbox,
.p-field-radiobutton {
    margin-bottom: 1rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}

.p-field-checkbox>label,
.p-field-radiobutton>label {
    margin-left: 0.5rem;
    line-height: 1;
}

.verde {
    color: #4caf50;
}

.custom-spinner .p-progress-spinner-circle {
    stroke: #873EFE !important;
}

.rojo {
    color: #D72323;
}

.naranja {
    color: #d78923;
}

.negro {
    color: #000000;
}

.azul {
    color: rgba(21, 49, 255, 1);
}

.w-0 {
    width: auto !important;
}

.border-bottom {
    border-bottom: 1px solid #d1d1d1;
}

.line {
    background: #4caf50;
    border-radius: 10px;
    width: 8px;
    height: 30px;
    margin: 0px 10px;
}

.lineOrange {
    background: #d78923;
    border-radius: 10px;
    width: 8px;
    height: 30px;
    margin: 0px 10px;
}

.lineRed {
    background: #D72323;
    border-radius: 10px;
    width: 8px;
    height: 30px;
    margin: 0px 10px;
}

.img-analista {
    border: 1px solid #c5c5c5;
    width: 40px;
    height: 40px;
    border-radius: 100%;
}

.footer-folio {
    background: #fbfbfb;
    padding: 16px 24px;
    box-shadow: 0px -6px 7px 0px rgb(157 157 157 / 25%);
    border-radius: 20px 20px 0px 0px;
}

.p-timeline-event-opposite {
    flex: 0 !important;
}

.p-button-white {
    background-color: white;
    color: #873EFE;
    border: 1px solid #873EFE;
    padding: 0.429rem 0.571rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 4px;
    width: 100%;
}

.photo-perfil {
    border: 1px solid #c5c5c5;
    width: 100px;
    height: 100px;
    border-radius: 100%;
}

.camera-perfil {
    position: relative;
    left: -30px;
}

.position-btns {
    position: relative;
    bottom: -20px;
    z-index: 999;
}

.dup-alert {
    background: #D34C00;
    border-radius: 50%;
    color: #D34C00;
    width: 50px;
    height: 50px;
    padding: 30px;
}

.bor-hist {
    border: 1px solid rgba(68, 72, 109, 0.2);
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
}

.footer-view {
    border-top: 1px solid #ddd;
}

.p-panel-title {
    width: 100%;
}

.recentlyAdded {
    color: #a07ed7;
}

.border-table {
    border-bottom: 1px solid rgb(200 200 200);
}

.bg-gris {
    background: #F0F0F0;
}

/* Refactor */

.datos-generales-labels {
    width: 100%;
    background: #e8e8e87a;
    border-radius: 10px;
    padding: 8px;
    word-break: break-all;
    color: #000000
}

.datos-generales-section-title {
    font-size: 1.25rem;
    font-family: inherit;
    font-weight: 600;
    line-height: 1.2;
}

.solicitador-input-text {
    color: #000000
}

.solicitador-button {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: 2px solid #e0e0e0;
    border-radius: 10px;
    height: 100%
}

.solicitador-notarios-dropdown {
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    height: 38px;
    word-break: break-all;
    color: #000000
}

.documentos-file-icon {
    font-size: 1.5em;
}

.verdeLine {
    background: #4caf50;
    border-radius: 10px;
    width: 8px;
    height: 30px;
    margin: 0px 10px;
}

.naranjaLine {
    background: #d78923;
    border-radius: 10px;
    width: 8px;
    height: 30px;
    margin: 0px 10px;
}

.rojoLine {
    background: #D72323;
    border-radius: 10px;
    width: 8px;
    height: 30px;
    margin: 0px 10px;
}

.negroLine {
    background: #000000;
    border-radius: 10px;
    width: 8px;
    height: 30px;
    margin: 0px 10px;
}