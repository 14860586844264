.steps-tooltips {
    display: flex;
    justify-content: space-around;
}

.p-steps-title {
    color: 'red'
}

.step-tooltip {
    position: relative;
    cursor: pointer;
}

.step-tooltip.active {
    color: #007ad9;
    /* Color activo */
}

.tooltip-text {
    visibility: hidden;
    width: 160px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -80px;
    opacity: 0;
    transition: opacity 0.3s;
}

.step-tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}