.bar-group .name-label {
    text-anchor: end;
    font-weight: 300;
    font-size: 1em;
    fill: #333;
}

.bar-group .value-label {
    text-anchor: end;
    fill: #000;
    font-weight: 700;
    font-size: 0.7em;
}

.container .bar-group {
    transition: opacity 0.3s;
}

/* Fade bars when the chart is hovered */
.container:hover .bar-group {
    opacity: 0.3;
}

/* But don't fade the hovered bar... */
.container .bar-group:hover {
    opacity: 1;
}

.container .bar-group:hover .name-label {
    font-weight: 400;
}

.container .bar-group:hover .value-label {
    font-size: 0.8em;
}