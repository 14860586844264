.tabs {
    font-family: arial;
    margin: 0 auto;
    width: 100%;
}

.tab-buttons {
    margin-bottom: 30px;
}

button {
    margin-right: 15px;
    font-size: 16px;
    background: transparent;
    border: none;
    outline: none;
    padding: 10px 20px;
    cursor: pointer;
    color: rgba(0, 0, 0, .4);
    transition: all ease-in-out .2s;
    border-bottom: 2px solid transparent;

}

.active {
    border-bottom: 2px solid #731ffe;
}

.inactive {
    color: black;
}

.buttonDisabled {
    color: darkgray
}